import React from "react"
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
//import Slider from "react-slick";

class WeFocused extends React.Component {

  render() {

    // var settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //   };

    return (

      <section className="we-focus">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Blockchain Development Services <span className="bluecolor">We Focus</span>
            </h2>
            <p className="text-center">
            Unlock the potential of blockchain development services to your enterprise with relevant blockchain technology that helps you meet the innovation with guaranteed performance. Explore more insights about the diverse blockchain development solutions we serve for your enterprise's success.
            </p>
            <Tab.Container id="left-tabs-example" defaultActiveKey="start" transition={false}>
                <Row>
                    <Col xl={4} lg={12} className="p-0 choose-tab">
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                        <Nav.Link eventKey="start">1. Blockchain Consulting Services</Nav.Link>
                        <Nav.Link eventKey="first">2. Custom Blockchain Development</Nav.Link>
                        <Nav.Link eventKey="second">3. Cryptocurrency Exchange Development</Nav.Link>
                        <Nav.Link eventKey="third">4. Cryptocurrency Wallet Development</Nav.Link>
                        <Nav.Link eventKey="four">5. Cryptocurrency Payment Gateway Development</Nav.Link>
                        <Nav.Link eventKey="five">6. Trading Bot Development</Nav.Link>
                        <Nav.Link eventKey="six">7. Defi Development</Nav.Link>
                        <Nav.Link eventKey="seven">8. NFT Development</Nav.Link>
                        <Nav.Link eventKey="eight">9. Smart Contract Development</Nav.Link>
                        <Nav.Link eventKey="nine">10. DApp Development</Nav.Link>
                        <Nav.Link eventKey="ten">11. Crypto Token Development</Nav.Link>
                        <Nav.Link eventKey="end">12. Crowdfunding Platform Development</Nav.Link>
                        </Nav.Item>
                    </Nav>  
                    </Col>
                    <Col xl={8} lg={12} className="contents"> 
                    <Tab.Content>
                        <Tab.Pane eventKey="start"> 
                            <h3>Blockchain Consulting Services</h3>
                            <p>Coinsclone being a superior blockchain development company, has a team of experienced Blockchain Consultants. These experts will assist in navigating through the complexities of blockchain technology. From providing strategic business development advisories and complex feature integration to code-customizing smart contracts, our experts provide warrantied assistance to meet future demands.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="first"> 
                            <h3>Custom Blockchain Development</h3>
                            <p>Custom blockchain development involves designing, developing & customizing the blockchain framework to suit your business requirements. It comes with enormous advantages like cost-efficiency, customization, speed, and easy access. We provide custom blockchain solutions for more than 10+ industries including, supply chain, banking, healthcare, education, and more. Our solutions help businesses maintain reliability, credibility, and blockchain resources effectively under secure access.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <h3>Cryptocurrency Exchange Development</h3>
                            <p><a href="https://www.coinsclone.com/cryptocurrency-exchange-development-company/"><b>Cryptocurrency Exchange Development</b></a> aids in creating a smart and unique centralized, decentralized, and hybrid exchange to serve the needs of novice and expert traders. Get your crypto exchange support in mobile and web platforms packed with multiple trading options, security protocols, and add-on modules. </p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <h3>Cryptocurrency Wallet Development </h3>
                            <p><a href="https://www.coinsclone.com/cryptocurrency-wallet-development-company/"><b>Cryptocurrency Wallet Development</b></a> is the process of creating a fully-featured crypto wallet that enables your users to control their cryptocurrency to have smooth digital transactions. Chase the opportunities with diverse use cases like DeFi staking, cryptocurrency trading, and more with cryptocurrency wallet solutions.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="four">
                            <h3>Cryptocurrency Payment Gateway Development</h3>
                            <p><a href="https://www.coinsclone.com/cryptocurrency-payment-gateway-development/"><b>Crypto Payment Gateway Development</b></a> refers to creating a feature-rich payment processing platform for the users to accept payments of multi-cryptocurrency without any hindrance. It is capable of supplying seamless transactions while keeping security intact & upgrades with a custom cryptocurrency payment gateway solution.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="five">
                            <h3>Trading Bot Development</h3>
                            <p>Revolutionize your experience of trading with our <a href="https://www.coinsclone.com/cryptocurrency-trading-bot-development/"><b>Trading Bot Development services</b></a>. Our bots are developed using advanced algorithms to automate the trading process eliminating human intervention. We offer a wide range of trading bot development services including the development of Arbitrage Bots, Flash loan trading bots, and Sniper Bots.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="six">
                            <h3>DeFi Development</h3>
                            <p>Kick Start your Defi Platform with our top-notch <a href="https://www.coinsclone.com/defi-development-company/"><b>DeFi Development services</b></a>. We provide end-to-end DeFi solutions, that could make your defi platform unique, competitive and feature-rich. Our DeFi Development solutions come with a process of creating and integrating Defi applications such as Defi Yield Farming Platform Development,<a href="https://www.coinsclone.com/defi-staking-platform-development/"><b>Defi Staking Platform Development</b></a>, Defi Lending Platform Development and Defi DEX Platform Development.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="seven">
                            <h3>NFT Marketplace Development</h3>
                            <p>Elevate your NFT enterprise with our cutting-edge <a href="https://www.coinsclone.com/nft-token-development/"><b>NFT Development services</b></a>. The NFT tokens we create are used to represent ownership of digital assets like art, music, gaming, real estate and more. It is built on secure blockchain platforms such as Ethereum, BSC, and Flow for transparency and reliability. We help you to develop and integrate some NFT solutions like <a href="https://www.coinsclone.com/nft-wallet-development-company/"><b>NFT Wallet Development</b></a>, NFT Staking Platform Development, <a href="https://www.coinsclone.com/nft-lending-platform-development/"><b>NFT Lending Platform Development</b></a> and NFT Minting Platform Development</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="eight">
                            <h3>Smart Contract Development</h3>
                            <p>Uncover the power of <a href="https://www.coinsclone.com/smart-contract-development-company/"><b>Smart Contract development</b></a> that is precisely supercharged with eminent blockchains like Ethereum, Polygon, Solana, and more to obtain efficiency and automation. Minimize the functional flaws, and enhance access and control with custom smart contract solutions.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="nine">
                            <h3>dApps Development</h3>
                            <p>Smart contract-powered DApp development could assist you in promoting enterprise standards keeping user privacy and platform simplicity. Revolutionize the way with custom <a href="https://www.coinsclone.com/dapp-development-company/"><b>dApp development solutions</b></a> specially created for business innovations that hold a broad layer of scalability and compatibility. </p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="ten">
                            <h3>Crypto Token Development</h3>
                            <p>As a premier Blockchain development company, we offer the best <a href="https://www.coinsclone.com/crypto-token-development/"><b>Crypto Token Development services</b></a>.  Our full-fledged crypto tokens are tailored to serve diverse applications as per industry norms. Explore endless possibilities with fungible and non-fungible tokens that are well-calibrated for specific use cases and applications as per business conditions.</p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="end"> 
                            <h3>Crowdfunding Platform Development </h3>
                            <p><a href="https://www.coinsclone.com/sto-development-company/"><b>STO Development Company</b></a> and <a href="https://www.coinsclone.com/ico-development-company/"><b>ICO Development Company</b></a> aim to satisfy startups’ fundraising goals more quickly and effortlessly with top-rated STO/ICO campaigns. STO/ICO platform development helps you offer graded security, efficiency, and transparency which are beneficial factors for marketing and promotional activities. </p>
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            <div className="mt-1 pt-lg-1 text-center d-none d-xl-block">
                <a href="/contact-us/" className="bluebtn mt-3 mx-auto d-table">Hire Our Blockchain Developers</a>
            </div>
          </div>
      </section>

    )
  }
}

export default WeFocused
